<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col md="6">
          <h5 class="text-uppercase heading">Assets</h5>
        </b-col>
        <b-col md="6" class="text-right">
          <assets-create @create="fetchData"></assets-create>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="2">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="9">
          <!-- Filter -->
          <b-form-group label="" label-for="filter-input" class="mb-0">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1" class="d-flex justify-content-end">
          <b-button variant="success" @click="downloadCSV">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        :filter="filter"
        @filtered="filtered"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        head-variant="none"
        table-variant="none"
        :busy="isBusy"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{
            (currentPage - 1) * item_per_page + (data.index + 1)
          }}</span>
        </template>

        <template #cell(actions)="row">
          <router-link :to="'/assets/' + row.item._id"
            ><i class="fas fa-eye view-icon" aria-hidden="true"></i>
          </router-link>
        </template>

        <template #cell(created_ts)="data">
          {{ data.item.created_ts | moment('ddd,Do MMM  YYYY') }}
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import assetsCreate from '@/components/assets/create';
import moment from 'moment';
export default {
  name: 'Users',
  components: {
    assetsCreate,
  },
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      filter: null,
      isBusy: false,
      fields: [
        '#',
        {
          key: '_assetCategoryID.name',
          label: 'Category',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
          sortable: true,
        },
        {
          key: 'grade',
          label: 'Grade',
          sortable: true,
        },
        {
          key: 'created_ts',
          label: 'Registration Date',
          sortable: true,
        },
        'actions',
      ],
      items: [],
      downloadItems: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {
    filtered(filteredItems) {
      this.downloadItems = filteredItems;
    },
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    fetchData() {
      this.isBusy = true;
      window.axios.get('/assets').then((resp) => {
        if (resp.data.success) {
          this.items = resp.data.data;
          this.downloadItems = this.items;
          this.isBusy = false;
        }
        console.log(resp.data.data);
      });
    },
    downloadCSV() {
      let csv = '\ufeff' + 'CATEGORY,NAME,CODE,GRADE,REGISTRATION DATE\n';
      this.downloadItems.forEach((el) => {
        var line =
          el['_assetCategoryID'].name +
          ',' +
          el['name'] +
          ',' +
          el['code'] +
          ',' +
          el['grade'] +
          ',' +
          `${moment(el['created_ts']).format('DD-MM-YYYY')}` +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Assets.csv';
      link.click();
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #454545;
}

.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}

/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
