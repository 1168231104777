<template>
  <div>
    <b-button size="sm" variant="success" v-b-modal.modal-1>New Asset</b-button>
    <b-modal id="modal-1" title="Register New Assets" size="xl" hide-footer>
      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Plantation" active>
            <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
              <div class="row">

                <div class="col-md-6">
                 
                  <b-form-group
                    id="input-group-1"
                    label="Name:"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.name"
                      type="text"
                      placeholder="Enter Asset Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Code:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.code"
                      placeholder="Enter Asset Code"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Description:"
                    label-for="input-2"
                  >
                    <b-form-textarea
                      id="input-2"
                      v-model="form.description"
                      placeholder="Enter description..."
                      rows="5"
                      max-rows="8"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Grade:"
                    label-for="input-2"
                  >
                    <b-form-select
                      id="input-2"
                      v-model="form.grade"
                      placeholder="Enter Grade"
                      required
                    >
                     <template #first>
                        <b-form-select-option value="" disabled>Select Grade</b-form-select-option>
                    </template>
                    <b-form-select-option value="A" selected>Grade A</b-form-select-option>
                    <b-form-select-option value="B">Grade B</b-form-select-option>
                    <b-form-select-option value="C">Grade C</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Plant Category:"
                    label-for="input-2"
                  >
                    <b-form-select
                      v-model="form._plantAssetCategoryID"
                      :options="options"
                      value-field="_id"
                      text-field="name"
                    >
                    <template #first>
                        <b-form-select-option value="" disabled>Select Plant Category</b-form-select-option>
                    </template>
                     
                     </b-form-select>

                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Image:"
                    label-for="input-2"
                  >
                    <b-form-file
                      id="file"
                      ref="file"
                      v-model="form.image"
                      :state="Boolean(form.image)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @change="uploadImage"
                    ></b-form-file>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-button size="lg" type="submit" variant="success" > Save </b-button>
                  
                </div>
              </div>
            </b-form>
          </b-tab>
          <b-tab title="Live Stock" disabled><p>Live Stock</p></b-tab>
          <b-tab title="Fixed Asset" disabled><p>Fixed Asset</p></b-tab>
          <b-tab title="Cottage" disabled><p>Fixed Asset</p></b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        code: "",
        image: "",
        description: "",
        grade: "",
        _plantAssetCategoryID: "",
      },
      options: [],
      success: false,
      plantCategoriesOptions: [],
    };
  },
  mounted() {
    this.getplantCategoryType();
    this.getassetCategoryType();
  },
  methods: {
    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },
    getplantCategoryType() {
      window.axios.get("assets/plant/category/list").then((resp) => {
        this.options = resp.data.data;
      });
    },
    getassetCategoryType() {
      window.axios.get("/assets/category/list").then((resp) => {
        this.plantCategoriesOptions = resp.data.data;
      });
    },
    submitForm() {
      let formData = new FormData();
      
     // formData.append("_assetCategoryID", this.form._assetCategoryID);
      formData.append("name", this.form.name);
      formData.append("code", this.form.code);
      formData.append("description", this.form.description);
      formData.append("grade", this.form.grade);
      formData.append("_plantAssetCategoryID", this.form._plantAssetCategoryID);
      formData.append("image", this.form.image);

      window
        .axios({
          method: "post",
          url: "assets",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.success                    = resp.data.message;
            this.form.name                  = "";
            this.form.code                  = "";
            this.form.description           = "";
            this.form.grade                 = "";
            this.form._plantAssetCategoryID = "";
            this.form.image                 = "";
            this.$emit("create", resp.data);
             setTimeout(() => (this.success = false), 3000);
          }
         
        });
    },
  },
};
</script>